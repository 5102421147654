const ReactGA = require('react-ga')

exports.initializeAnalytics = () => {
  ReactGA.initialize('UA-101771477-3')
  ReactGA.set({ userId: Math.floor(Math.random() * 100) + Date.now() })
  // window.sessionStorage.setItem("correlationId", Math.floor(Math.random() * 100)+Date.now())
}

exports.registerPageView = location => {
  ReactGA.pageview(location)
}

exports.registerEvent = event => {
  ReactGA.event({ ...event })
}
