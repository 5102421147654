/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const Analytics = require('./src/utils/analytics')

exports.onClientEntry = () => {
  Analytics.initializeAnalytics()
}

exports.onPreRouteUpdate = ({ location }) => {
  console.log('onPreRouteUpdateLocationOBJ: ', location)
}

exports.onRouteUpdate = ({ location }) => {
  console.log('onRouteUpdateLocationOBJ: ', location)

  // Track pageview with google analytics
  Analytics.registerPageView(location.pathname + location.search)
}
